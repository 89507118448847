import React, { useEffect } from 'react'
import { Bars } from 'react-loader-spinner'
import { AiFillApple, AiFillAndroid } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import useFetch from '../../hook/useFetch'
import { BsFillPersonFill } from 'react-icons/bs'
import { MdOutlinePrivacyTip } from "react-icons/md";

const LandingPage = () => {
    const history = useNavigate()
    const { data, isLoading, err, reFetch, fetchData } = useFetch('/get_app_ids')
    return (
        <div className='bgimg w3-display-container w3-animate-opacity w3-text-white'>
            <div className="w3-display-topleft w3-padding-large w3-xlarge headerLandingPage">
                    <img src={require('./Logo.jpg')} className='logo-landing-page' />
                <div className='navbarCustom'>
                    <div className='navbar-item' onClick={() => {history('/account_deletion')}}>
                        Smazání účtu
                    </div>
                    <div className='navbar-item' onClick={() => {history('/tos')}}>
                        TOS
                    </div>
                    <div className='navbar-item' onClick={() => {history('/user_add_drink')}}>
                        Navrhnout pití
                    </div>
                    <div className='navbar-item' onClick={() => {history('/dashboard')}}>
                        Admin
                    </div>
                </div>
            </div>
            <div className="w3-display-middle">
                <h1 className="w3-jumbo w3-animate-top" style={{textAlign: "center", fontSize: "3.5rem"}}>ALKOHOLICKÝ DENÍK</h1>
                <hr className="w3-border-black" style={{margin: "auto", width: "60%"}} />
                <div className="w3-large w3-center icons">
                    {
                        isLoading ? <Bars
                            height="60"
                            width="80"
                            color="white"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        /> : <>
                            <div className='iconcontainter' onClick={() => history(`/download/${data[0].app_id}`)} ><AiFillAndroid size={35} /></div>
                            <div className='iconcontainter' onClick={() => history(`/download/${data[1].app_id}`)} ><AiFillApple size={35} /></div>
                        </>
                    }
                </div>
            </div>
        </div>
  )
}

export default LandingPage