import React from 'react'

import { MdEdit } from "react-icons/md";
import { useDataContext } from '../../../../DataContext';

import { FaRegEye } from "react-icons/fa6";
import { FaRegEyeSlash } from "react-icons/fa6";

const Drink = ({ item }) => {
  const { history } = useDataContext()
  return (
    <div className='drinkEdit_drink'>
      <div className='drinkinsideEdit_drink'>
        <div>{item.hidden ? <FaRegEyeSlash size={22} color="red" /> : <FaRegEye size={22} color="#0d4c92" />}</div>
        <h4>{item.name}</h4>
      </div>
      <MdEdit size={22} color='#0d4c92' onClick={() => history(`/edit_drink/${item.id_drink}`)} style={{ cursor: "pointer"}} />
    </div>
  )
}

export default Drink