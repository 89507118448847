import React, { useEffect, useRef } from 'react'
import { useDataContext } from '../../../DataContext'
import Options from '../Add_drink/Options'
import Categories from '../Add_drink/Categories'
import { useParams } from 'react-router-dom'
import useFetch from '../../../hook/useFetch'
const Drink = () => {
  const { id_drink } = useParams()
  const { history, apiLink, token } = useDataContext()
  const { data, isLoading, err, reFetch, fetchData } = useFetch(`/user_added/get_user_created_drink/${id_drink}`, {
    method: "GET",
    headers: {
        token
    }
  }, false)

  useEffect(() => {
      if(token && id_drink) fetchData()
  }, [token, id_drink])

  useEffect(() => {
    if(!data) return
    
    name.current.value = data.name
    alkohol_percentage.current.value = data.alkohol_percentage
    energy.current.value = data.energy
    id_category.current.value = data.id_category
    id_sizes.current.value = data.id_sizes

  }, [data])

  const id_category = useRef()
  const name = useRef()
  const energy = useRef()
  const alkohol_percentage = useRef()
  const id_sizes = useRef()

  const handleSubmit = async (e) => {
      e.preventDefault()
      const drink = {
        id_drink: data.id_drink,
        drink: {
          name: name.current.value,
          alkohol_percentage: alkohol_percentage.current.value,
          energy: energy.current.value,
          id_category: id_category.current.value,
          id_sizes: id_sizes.current.value,
          hidden: false
        }
      }

      fetch(apiLink + "/user_added/approve_drink", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token
          },
          body: JSON.stringify(drink)
      })
      history(-1)
  }

  console.log(data)

  return (
    <div className='bgimg'>
        <div className='header'>
          <button className='backHeader' onClick={() => history(-1)}>Back</button>
        </div>
        {data && <div>
            <form onSubmit={handleSubmit} className='user_add_drink_container'>
                <h1>Přidat nápoj</h1>
                <label htmlFor="name" className='labelAdd_drink'>Jméno nápoje</label>
                <input type="text" className='inputAdd_drink' id="name" ref={name} required />

                <label htmlFor="category" className='labelAdd_drink'>Kategorie</label>
                <select name="category" className='inputAdd_drink' ref={id_category} defaultValue={data.id_category}>
                    <Categories />
                </select>
                <label htmlFor="quantity" className='labelAdd_drink'>Množství</label>
                <select name="quantity" className='inputAdd_drink' ref={id_sizes} defaultValue={data.id_sizes}>
                    <Options />
                </select>
                <label htmlFor="alkohol_percentage" className='labelAdd_drink'>Procenta</label>
                <input type="number" className='inputAdd_drink' id="alkohol_percentage" ref={alkohol_percentage} step={0.1} required />
                <label htmlFor="energy" className='labelAdd_drink'>Kalorie</label>
                <input type="number" className='inputAdd_drink' id="energy" ref={energy} step={0.1} required />
                <button type='submit' className='buttonAdd_drink'>Přidat</button>
            </form>
        </div>}
    </div>
  )
}

export default Drink