import React, { useEffect } from 'react'
import Drink from './Drink'
import useFetch from '../../../../hook/useFetch'
import { useDataContext } from '../../../../DataContext'
import Header from '../../Header/Header'
const Drinks = () => {
    const { token } = useDataContext()
    const { data, isLoading, err, reFetch, fetchData } = useFetch("/admin_page/get_drinks", {
        headers: {
            token
          },
    }, false)

    useEffect(() => {
        if(token) fetchData()
    }, [token])

    return (
        <>
            <Header />
            <div>
                {data && data.map(item => (
                    <Drink item={item} key={item.id_drink} />
                ))}
            </div>   
        </>
    )
}

export default Drinks