import DownloadSubPage from "./pages/LandingPage/DownloadSubPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import { DataProvider } from './DataContext'
import Login from "./pages/Admin_Dashboard/Login/Login";
import PrivateRoute from "./PrivateRoute";
import DashBoard from "./pages/Admin_Dashboard/Dashboard";
import Add_drink from "./pages/Admin_Dashboard/Add_drink/Add_drink";
import Add_Sizes from "./pages/Admin_Dashboard/Add_Sizes/Add_Sizes";
import Edit_drink from './pages/Admin_Dashboard/Edit_drink/Edit_drink'
import Drinks from "./pages/Admin_Dashboard/Edit_drink/Drinks/Drinks";
import TOS from "./pages/LandingPage/TOS";
import User_add_drink from "./pages/User_add_drink/User_add_drink";
import User_created_drinks from "./pages/Admin_Dashboard/User_created_drinks/User_created_drinks";
import Drink from "./pages/Admin_Dashboard/User_created_drinks/Drink";
import Lost_Password from "./pages/Lost_Password/Lost_Password";
import Account_Deletion from "./pages/Account_Deletion/Account_Deletion";

const App = () => {
  return (
    <Router className="App">
      <DataProvider>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/download/:appId" element={<DownloadSubPage />} />
          <Route path="/tos" element={<TOS />} />
          <Route path="/user_add_drink" element={<User_add_drink />} />
          <Route path="/lost_password" element={<Lost_Password />} />
          <Route path="/account_deletion" element={<Account_Deletion />} />

          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute><DashBoard /></PrivateRoute>} />
          <Route path="/add_drink" element={<PrivateRoute><Add_drink /></PrivateRoute>} />
          <Route path="/add_sizes" element={<PrivateRoute><Add_Sizes /></PrivateRoute>} />
          <Route path="/drinks_to_edit" element={<PrivateRoute><Drinks /></PrivateRoute>} />
          <Route path="/edit_drink/:id_drink" element={<PrivateRoute><Edit_drink /></PrivateRoute>} />
          <Route path="/user_created_drinks" element={<PrivateRoute><User_created_drinks /></PrivateRoute>} />
          <Route path="/user_created_drinks/drink/:id_drink" element={<PrivateRoute><Drink /></PrivateRoute>} />
        </Routes>
      </DataProvider>
    </Router>
  )
}

export default App;
