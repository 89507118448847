import React, { useRef, useState } from 'react'
import Quantities from './Quantities/Quantities'
import { CiCirclePlus } from "react-icons/ci";
import { useDataContext } from '../../../DataContext'
import Header from '../Header/Header';

const Add_Sizes = () => {
    const { apiLink, token } = useDataContext()
    const name = useRef()
    const unit = useRef()
    const [quantities, setQuantities] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault()
        
        let _unit = unit.current.value
        let multiplier = 1
        if(_unit == "L"){
            multiplier = 1
        }else if(_unit == "dcl"){
            multiplier = 10
        }else if(_unit == "cl"){
            multiplier = 100
        }else if(_unit == "ml"){
            multiplier = 1000
        }

        const sizes = {
            name: name.current.value,
            unit: unit.current.value,
            multiplier,
            quantities
        }
        fetch(apiLink + "/admin_page/create_sizes", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token
            },
            body: JSON.stringify(sizes)
        })
    }

    return (
        <div>
            <Header />
            <form onSubmit={handleSubmit} className='add_drink_container'>
                <h1>Přidat velikosti</h1>
                <div style={{width: "90%"}}>
                    <label htmlFor="name" className='labelAdd_drink'>Jméno velikosti</label>
                    <input type="text" className='inputAdd_drink' style={{width: "100%"}} id="name" ref={name} required />
                </div>
                <div  style={{width: "90%"}}>
                    <label htmlFor="unit" className='labelAdd_drink'>
                        Jednotka
                    </label>
                    <select name="unit" className='inputAdd_drink' style={{width: "100%"}} ref={unit}>
                        <option value={"L"} key={"L"}>L</option>
                        <option value={"dcl"} key={"dcl"}>dcl</option>
                        <option value={"cl"} key={"cl"}>cl</option>
                        <option value={"ml"} key={"ml"}>ml</option>
                    </select>
                </div>
                <Quantities quantities={quantities} setQuantities={setQuantities} />
                <button type='submit' className='buttonAdd_drink'>Vytvořit</button>
            </form>
        </div>
    )
}

export default Add_Sizes