import React from 'react'

import { CiCircleRemove } from "react-icons/ci";

const Quantity = ({ item, quantities, setQuantities, index }) => {
  const remove = async () => {
    const i = quantities.indexOf(item);
    if (i > -1) {
      let arr = quantities
      arr.splice(i, 1)
      await setQuantities([])
      await setQuantities(arr)
    }
  }

  return (
    <div className='quantityAdd_sizes'>
      {item.size}
      <CiCircleRemove size={22} color='#0d4c92' onClick={remove} />
    </div>
  )
}

export default Quantity