import React, { useEffect, useRef, useState } from 'react'
import Quantity from './Quantity'
import { CiCirclePlus } from 'react-icons/ci'

const Quantities = ({ quantities, setQuantities}) => {
    const size = useRef()

    const add_quantity = () => {
        if(size.current.value == "") return
        if(size.current.value == 0) return
        let arr = [...quantities]

        arr.push({ index: arr.length, size: size.current.value})

        arr.sort(function(a, b){
            return a.size - b.size;
        })

        setQuantities(arr)

        size.current.value = ""
    }

    return (
        <>
            <div className='inputContainerAdd_sizes'>
                <div>
                        <label htmlFor="name" className='labelAdd_drink'>Velikost</label>
                        <input type="number" className='inputAdd_drink' id="name" ref={size} max={5} min={0} step={0.1} />
                </div>
                <CiCirclePlus size={50} color="#0d4c92" onClick={add_quantity} style={{cursor: "pointer"}} />
            </div>
            {quantities.map((item, index) => (
                <Quantity item={item} index={index} key={index} quantities={quantities} setQuantities={setQuantities} />
            ))}
        </>
    )
}

export default Quantities