import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Bars } from 'react-loader-spinner'
const DownloadSubPage = () => {
    const { appId } = useParams()

    const [loading, setLoading] = useState(true)
    const handleLoad = () => {
        setLoading(false)
    }
    return (
        <div className='downloadSubContainer bgimg'>
            {loading && <Bars
                height="60"
                width="80"
                color="white"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />}
            <img src={`https://qr.expo.dev/eas-update?updateId=${appId}&appScheme=exp&host=u.expo.dev`} onLoad={handleLoad} style={{ display: loading ? 'none' : 'block' }} alt="" className='imageQRCODE' />
            <a className='QRCODEtext' href={`exp://u.expo.dev/update/${appId}`}>exp://u.expo.dev/update/{appId}</a>
        </div>
    )
}

export default DownloadSubPage