import React from 'react'
import { useDataContext } from '../../DataContext'

const DashBoard = () => {
    const { history } = useDataContext()
    return (
        <div className='bgimg'>
            <div className='header'>
                <button className='backHeader' onClick={() => history("/")}>Back</button>
            </div>
            <div className='buttonsDashboard'>
            <button className='buttonButtonsDashboard' onClick={() => history('/add_drink')}>Přidat nápoj</button>
            <button className='buttonButtonsDashboard' onClick={() => history('/add_sizes')}>Přidat velikost nápoje</button>
            <button className='buttonButtonsDashboard' onClick={() => history('/drinks_to_edit')}>Upravit nápoj</button>
            <button className='buttonButtonsDashboard' onClick={() => history('/user_created_drinks')}>user_created_drinks</button>
            <button className='buttonButtonsDashboard' onClick={() => history()}>XD</button>
            <button className='buttonButtonsDashboard' onClick={() => history()}>XD</button>
            <button className='buttonButtonsDashboard' onClick={() => history()}>XD</button>
            <button className='buttonButtonsDashboard' onClick={() => history()}>XD</button>
            <button className='buttonButtonsDashboard' onClick={() => history()}>XD</button>
            <button className='buttonButtonsDashboard' onClick={() => history()}>XD</button>
            <button className='buttonButtonsDashboard' onClick={() => history()}>XD</button>
            <button className='buttonButtonsDashboard' onClick={() => history()}>XD</button>
        </div>
        </div>
    )
}

export default DashBoard