import { useEffect } from 'react'
import { useDataContext } from '../../../DataContext'
import useFetch from '../../../hook/useFetch'

const Options = ({ id_category }) => {
    const { data, isLoading, err, reFetch, fetchData } = useFetch("/admin_page/get_categories")

    return (
        <>
            {data && data.map(item => (
                <option value={item.id_category} key={item.id_category}>{item.name}</option>
            ))}
        </>
    )
}

export default Options