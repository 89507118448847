import { useEffect } from 'react'
import { useDataContext } from '../../../DataContext'
import useFetch from '../../../hook/useFetch'

const Options = () => {
    const { data, isLoading, err, reFetch, fetchData } = useFetch("/admin_page/get_sizes")

    return (
        <>
            {data && data.map(item => (
                <option value={item.id_sizes} key={item.id_sizes}>{item.name}</option>
            ))}
        </>
    )
}

export default Options