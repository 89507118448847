import React, { useEffect, useRef } from 'react'

import Categories from '../Add_drink/Categories'
import Options from '../Add_drink/Options'
import { useDataContext } from '../../../DataContext'
import Header from '../Header/Header'
import { useParams } from 'react-router-dom'
import useFetch from '../../../hook/useFetch'

import { FaRegEye } from "react-icons/fa6";
import { FaRegEyeSlash } from "react-icons/fa6";

const Edit_drink = () => {
    const { id_drink } = useParams()
    const { token, apiLink, history } = useDataContext()
    const name = useRef()
    const alkohol_percentage = useRef()
    const energy = useRef()
    const id_category = useRef()
    const id_sizes = useRef()

    const { data, isLoading, err, reFetch, fetchData } = useFetch("/admin_page/get_drink", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            token
        },
        body: JSON.stringify({ id_drink })
    }, false)

    useEffect(() => {
        if(token && id_drink) fetchData()
    }, [token])

    useEffect(() => {
        if(!data) return
        name.current.value = data.name
        alkohol_percentage.current.value = data.alkohol_percentage
        energy.current.value = data.energy
        id_category.current.value = data.id_category
        id_sizes.current.value = data.id_sizes
    }, [data])

    // console.log(data)

    const handleSubmit = (e) => {
        e.preventDefault()

        const drink = {
            id_drink,
            name: name.current.value,
            alkohol_percentage: alkohol_percentage.current.value,
            energy: energy.current.value,
            id_category: id_category.current.value,
            id_sizes: id_sizes.current.value,
        }

        fetch(apiLink + "/admin_page/edit_drink", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token
            },
            body: JSON.stringify(drink)
        })

        history('/drinks_to_edit')
    }

    const handleHide = async () => {
        await fetch(apiLink + '/admin_page/hide_drink', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token
            },
            body: JSON.stringify({id_drink})
        })

        reFetch()
    }

    return (
        <div>
            <Header />
            {data && <form onSubmit={handleSubmit} className='add_drink_container'>
                <h1>Upravit nápoj</h1>
                <h2>{ data.hidden ? <FaRegEyeSlash size={22} color="#0d4c92" /> : <FaRegEye size={22} color="#0d4c92" /> }</h2>
                <label htmlFor="name" className='labelAdd_drink'>Jméno nápoje</label>
                <input type="text" className='inputAdd_drink' id="name" ref={name} required />

                <label htmlFor="category" className='labelAdd_drink'>Kategorie</label>
                <select name="category" className='inputAdd_drink' ref={id_category} defaultValue={data.id_category}>
                    <Categories /> {/* TODO: opravit na onload se špatně nastavuje defaultValue na hostingu */}
                </select>

                <label htmlFor="quantity" className='labelAdd_drink'>Množství</label>
                <select name="quantity" className='inputAdd_drink' ref={id_sizes} defaultValue={data.id_sizes}>
                    <Options /> {/* TODO: opravit na onload se špatně nastavuje defaultValue na hostingu */}
                </select>

                <label htmlFor="alkohol_percentage" className='labelAdd_drink'>Procenta</label>
                <input type="number" className='inputAdd_drink' id="alkohol_percentage" ref={alkohol_percentage} required step={0.1} />
                <label htmlFor="energy" className='labelAdd_drink'>Kalorie</label>
                <input type="number" className='inputAdd_drink' id="energy" ref={energy} required />
                
                <button type='submit' className='buttonAdd_drink'>Upravit</button>
            </form>}
            <div className='hidebuttonAdd_drink'>
                <button className='buttonAdd_drink' onClick={handleHide}>Schovat</button>
            </div>
        </div>
    )
}

export default Edit_drink