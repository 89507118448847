import React from 'react'
import { useDataContext } from '../../../DataContext'

const Header = () => {
    const { history } = useDataContext()
    return (
        <div className='header'>
            <button className='backHeader' onClick={() => history(-1)}>Back</button>
        </div>
    )
}

export default Header