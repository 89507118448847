import React, { useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask';

import { useDataContext } from '../../DataContext'

const Lost_Password = () => {
    const { apiLink, history } = useDataContext()
    const email = useRef()
    const code = useRef(0)
    const password = useRef()
    const passwordAgain = useRef()

    const [emailSent, setEmailSent] = useState(false)


    const request_code = async (e) => {
        e.preventDefault()

        if(!check_if_email(email.current.value)) return

        setEmailSent(true)

        await fetch(apiLink + '/change/generate_code_for_email', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email.current.value })
        })
    }

    const check_if_email = (string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
        return emailRegex.test(string);
    }

    const change_password = async () => {
        if(password.current.value.length < 6) return alert("Heslo musí být aspoň 6 znaků dlouhé")
        if(password.current.value !== passwordAgain.current.value) return alert("Hesla se neshodují!")

        await fetch(apiLink + '/change/change_password_with_code', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email.current.value, password: password.current.value, code: (code.current.value).replace(" ", "") })
        })
    }

    return (
        <div className='bgimg'>
            <div className='header'>
                <button className='backHeader' onClick={() => history("/")}>Back</button>
            </div>
            <div className='loginContatiner'>
                <div className='tab-content'>
                    <div className='tab-pane fade show active'>
                        <form onSubmit={request_code} className='form-outline mb-2'>
                            <h1>email:</h1>
                            <input className="form-control" type="email" ref={email} disabled={emailSent} />
                            <button className="form-control mt-2" /* onClick={request_code} */ disabled={emailSent}>send</button>
                        </form>
                        <h1>code:</h1>
                        <InputMask className="form-control" mask="999 999" maskChar=" " ref={code} onChange={() => console.log(code.current.value)} />
                        <h1>password:</h1>
                        <input className="form-control" type="password" ref={password} />
                        <h1>passwordAgain:</h1>
                        <input className="form-control" type="password" ref={passwordAgain} />
                        <button className="form-control mt-2" onClick={change_password}>change</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Lost_Password