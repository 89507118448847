import React, { useRef } from 'react'
import { useDataContext } from '../../../DataContext'
import Options from './Options'
import Categories from './Categories'
import Header from '../Header/Header'

const Add_drink = () => {
    const { apiLink, token } = useDataContext()

    const name = useRef()
    const alkohol_percentage = useRef()
    const energy = useRef()
    const id_category = useRef()
    const id_sizes = useRef()
    
    const handleSubmit = (e) => {
        e.preventDefault()

        const drink = {
            name: name.current.value,
            alkohol_percentage: alkohol_percentage.current.value,
            energy: energy.current.value,
            id_category: id_category.current.value,
            id_sizes: id_sizes.current.value,
        }

        fetch(apiLink + "/admin_page/create_drink", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token
            },
            body: JSON.stringify(drink)
        })

        name.current.value = ""
        alkohol_percentage.current.value = ""
        energy.current.value = ""
        id_category.current.value = 1
        id_sizes.current.value = 1
    }

    return (
        <div>
            <Header />
            <form onSubmit={handleSubmit} className='add_drink_container'>
                <h1>Přidat nápoj</h1>
                <label htmlFor="name" className='labelAdd_drink'>Jméno nápoje</label>
                <input type="text" className='inputAdd_drink' id="name" ref={name} required />

                <label htmlFor="category" className='labelAdd_drink'>Kategorie</label>
                <select name="category" className='inputAdd_drink' ref={id_category}>
                    <Categories />
                </select>

                <label htmlFor="alkohol_percentage" className='labelAdd_drink'>Procenta</label>
                <input type="number" className='inputAdd_drink' id="alkohol_percentage" ref={alkohol_percentage} required />
                <label htmlFor="energy" className='labelAdd_drink'>Kalorie</label>
                <input type="number" className='inputAdd_drink' id="energy" ref={energy} required />
                <label htmlFor="quantity" className='labelAdd_drink'>Množství</label>
                <select name="quantity" className='inputAdd_drink' ref={id_sizes}>
                    <Options />
                </select>
                <button type='submit' className='buttonAdd_drink'>Přidat</button>
            </form>
        </div>
    )
}

export default Add_drink