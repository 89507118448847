import React, { useRef } from 'react'
import { useDataContext } from '../../DataContext'

import Categories from '../Admin_Dashboard/Add_drink/Categories'
import Options from '../Admin_Dashboard/Add_drink/Options'

const User_add_drink = () => {
    const { history, apiLink } = useDataContext()
    const id_category = useRef()
    const name = useRef()
    const energy = useRef()
    const alkohol_percentage = useRef()
    const id_sizes = useRef()

    const handleSubmit = async (e) => {
        e.preventDefault()
        const drink = {
            name: name.current.value,
            alkohol_percentage: alkohol_percentage.current.value,
            energy: energy.current.value,
            id_category: id_category.current.value,
            id_sizes: id_sizes.current.value,
        }

        fetch(apiLink + "/user_added/user_create_drink", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(drink)
        })

        name.current.value = ""
        alkohol_percentage.current.value = ""
        energy.current.value = ""
        id_category.current.value = 1
        id_sizes.current.value = 1
    }
    return (
        <div className='bgimg'>
            <div className='header'>
                <button className='backHeader' onClick={() => history("/")}>Back</button>
            </div>
            <div>
                <form onSubmit={handleSubmit} className='user_add_drink_container'>
                    <h1>Přidat nápoj</h1>
                    <label htmlFor="name" className='labelAdd_drink'>Jméno nápoje</label>
                    <input type="text" className='inputAdd_drink' id="name" ref={name} required />

                    <label htmlFor="category" className='labelAdd_drink'>Kategorie</label>
                    <select name="category" className='inputAdd_drink' ref={id_category}>
                        <Categories />
                    </select>
                    <label htmlFor="quantity" className='labelAdd_drink'>Množství</label>
                    <select name="quantity" className='inputAdd_drink' ref={id_sizes}>
                        <Options />
                    </select>
                    <label htmlFor="alkohol_percentage" className='labelAdd_drink'>Procenta</label>
                    <input type="number" className='inputAdd_drink' id="alkohol_percentage" ref={alkohol_percentage} step={0.1} required />
                    <label htmlFor="energy" className='labelAdd_drink'>Kalorie na 100ml</label>
                    <input type="number" className='inputAdd_drink' id="energy" ref={energy} step={0.1} required />
                    <button type='submit' className='buttonAdd_drink'>Přidat</button>
                </form>
            </div>
        </div>
    )
}

export default User_add_drink