import React, { useEffect } from 'react'

import { useDataContext } from '../../../DataContext'
import useFetch from '../../../hook/useFetch'

const User_created_drinks = () => {
    const { token, history, apiLink } = useDataContext()

    const { data, isLoading, err, reFetch, fetchData } = useFetch("/user_added/get_all_user_created_drinks", {
        method: "GET",
        headers: {
            token
        }
    }, false)

    useEffect(() => {
        if(token) fetchData()
    }, [token])
    
    const decline = async (id_drink) => {
        await fetch(apiLink + `/user_added/decline_drink/${id_drink}`,
            {
                method: "GET",
                headers: {
                    token
                }
            }
        )

        reFetch()
    }

    return (
        <div className='bgimg'>
            <div className='header'>
                <button className='backHeader' onClick={() => history('/dashboard')}>Back</button>
            </div>

            <div className='user_added_drink_container_container'>
                {data && data.map(item => (
                    <div className='user_added_drink_container' key={item.id_drink}>
                        <h2>{item.name}</h2>
                        <h2>{item.alkohol_percentage}%</h2>
                        <h4>{item.energy} kcal</h4>
                        <div className='user_added_quantities'>
                            {
                                item.size.quantities.map(item => (
                                    <div key={item.id_quantity}>
                                        {item.size} L
                                    </div>
                                ))
                            }
                        </div>
                        <div className='user_added_buttons'>
                            <button onClick={() => history(`/user_created_drinks/drink/${item.id_drink}`)}>Upravit</button>
                            <button onClick={() => decline(item.id_drink)}>NePřidat</button>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default User_created_drinks