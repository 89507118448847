import React, { useEffect, useRef, useState } from 'react'
import { useDataContext } from '../../DataContext'
import ReactInputMask from 'react-input-mask'
const Delete_Login = () => {
    const { apiLink, history } = useDataContext()
    const username = useRef(), password =  useRef(), code = useRef(0)
    const [token, setToken] = useState()
    const login = async (username, password) => {
        if(username.length < 0 || password.length < 0) return
        let res = await fetch(apiLink + '/auth/sign_in', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ username, password })
        })        
        let data = await res.json()
        if(data.err) return data
        setToken(data.token)
        return data
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(username.current.value.length < 1) return alert("Musíš vyplnit jméno!")
        if(password.current.value.length < 1) return alert("Musíš vyplnit heslo!")
        let err = await login(username.current.value, password.current.value)
        if(err.err) return alert("Špatné jméno nebo heslo!")
    }

    const deleteAccount = async () => {
        if(code.current.value.length !== 7) return console.log('xsadas')
        let res = await fetch(apiLink + '/account/delete_account', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', token},
            body: JSON.stringify({ code: code.current.value })
        })
        // let data = await res.json()
        // console.log(data)
    }

    const requestCode = async () => {
        let res = await fetch(apiLink + '/account/request_code_for_account_deletion', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', token},
            body: JSON.stringify({ code: code.current.value })
        })
    }

    return (
        <div className='bgimg'>
            <div className='header'>
                <button className='backHeader' onClick={() => history("/")}>Back</button>
            </div>
            {!token ? <div className='deleteContainer'>
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
                        <form onSubmit={handleSubmit}>
                            <h2 className='text-center pb-3'>Login</h2>
                            <div className="form-outline mb-2">
                                <input type="text" id="loginName" className="form-control" ref={username} />
                                <label className="form-label" htmlFor="loginName">Přihlašovací jméno</label>
                            </div>

                            <div className="form-outline mb-2">
                                <input type="password" id="loginPassword" className="form-control" ref={password} />
                                <label className="form-label" htmlFor="loginPassword">Heslo</label>
                            </div>
                            <button type='submit' className="btn mb-4 w-100 loginButton">Sign in</button>
                        </form>
                    </div>
                </div>
            </div>:
            <div className='deleteContainer'>
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
                        <h2 className='text-center pb-3'>Smazání účtu</h2>
                        
                        <div className="form-outline mb-2">
                            <label className="form-label" htmlFor="email">Kód</label>
                            <ReactInputMask className="form-control" mask="999 999" maskChar="" ref={code} onChange={()=> console.log(code.current.value.length)} />
                        </div>

                        <button className="btn mb-2 w-100 loginButton" onClick={requestCode}>Poslat kód na E-Mail</button>
                        <button className="btn mb-2 w-100 deleteButton" onClick={deleteAccount}>Smazat účet</button>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Delete_Login